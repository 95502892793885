<template>
  <div class="content-box">
    <a-modal
        :width="600"
        centered
        v-model="showModal"
        :title="changTypeTitle"
        @ok='handleConfirmChangeExtend'
        @cancel='cancel'
    >
      <div style="display: flex;justify-content: flex-start;align-items: center">
        <div class="flex-start-center">
          <span>描述：</span>
          <a-textarea
              allowClear
              v-model="params.confirmExtend"
              style="width:460px"
              :auto-size="{ minRows: 4, maxRows: 6 }"
              placeholder='请输入补充描述'
          ></a-textarea>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      params: {
        confirmExtend: undefined
      },
      changeType: '',
      changTypeTitle: '修改老师的描述',
      changeTypeApi: {
        text: '/dq_admin/duibanConfirmOrder/updateInfo',
        price: '/dq_admin/duibanConfirmOrder/refundDuibanOrder'
      }
    }
  },
  methods: {
    /** 确定修改 */
    async handleConfirmChangeExtend() {
      const res = await this.axios.post(this.changeTypeApi[this.changeType], {
        id: this.orderNo,
        ...this.params
      })
      if (res.status !== '200') return
      this.showModal = false
      this.$message.success('修改成功')
      this.$emit('change')
    },
    /** 显示 */
    async show(data, orderNo, type) {
      this.changeType = type
      if (type === 'text') {
        this.changTypeTitle = '修改老师的描述'
      } else {
        this.changTypeTitle = '退款'
      }
      Object.assign(this.params, data)
      this.orderNo = orderNo
      this.showModal = true
    },
    /** 关闭 */
    cancel() {
      this.showModal = false
    },
  }
}
</script>

<style lang="scss" scoped>
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.content-box {
  width: 600px;
}
.take-box{width:100%;}
.ml-20 {
  margin-left: 20px !important;
}
</style>