var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "店铺基本信息",
        placement: "right",
        closable: true,
        visible: _vm.showDrawer,
        width: "1100"
      },
      on: { close: _vm.onClose }
    },
    [
      _c(
        "a-descriptions",
        { attrs: { bordered: "" } },
        [
          _c(
            "a-descriptions-item",
            { attrs: { label: "店铺名称" } },
            [
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.baseInfo.shopName))
              ]),
              _c(
                "a-button",
                {
                  staticClass: "ml-20",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleShowEditAddress }
                },
                [_c("a-icon", { attrs: { type: "edit" } }), _vm._v("编辑")],
                1
              )
            ],
            1
          ),
          _c("a-descriptions-item", { attrs: { label: "昵称" } }, [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.baseInfo.userName))
            ])
          ]),
          _c("a-descriptions-item", { attrs: { label: "用户ID" } }, [
            _c(
              "span",
              {
                staticClass: "font-bold color-blue text-underline text-cursor",
                on: {
                  click: function($event) {
                    return _vm.handleGoToUserInfo(_vm.baseInfo.userId)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.baseInfo.userId))]
            )
          ]),
          _c(
            "a-descriptions-item",
            { attrs: { label: "店铺介绍", span: 3 } },
            [
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.baseInfo.shopInfo))
              ]),
              _c(
                "a-button",
                {
                  staticClass: "ml-20",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleShowEditAddress }
                },
                [_c("a-icon", { attrs: { type: "edit" } }), _vm._v("编辑")],
                1
              )
            ],
            1
          ),
          _c("a-descriptions-item", { attrs: { label: "其它平台店铺名称" } }, [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.baseInfo.otherShopName))
            ])
          ]),
          _c(
            "a-descriptions-item",
            { attrs: { label: "设置店铺星级", span: 2 } },
            [
              _c("a-rate", {
                attrs: {
                  defaultValue: _vm.baseInfo.star,
                  count: 6,
                  disabled: "",
                  tooltips: _vm.starDesc
                },
                model: {
                  value: _vm.starValue,
                  callback: function($$v) {
                    _vm.starValue = $$v
                  },
                  expression: "starValue"
                }
              })
            ],
            1
          ),
          _c("a-descriptions-item", { attrs: { label: "身份证姓名" } }, [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.baseInfo.identityCardName))
            ])
          ]),
          _c("a-descriptions-item", { attrs: { label: "身份证号", span: 2 } }, [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.baseInfo.identityCardNo))
            ])
          ]),
          _c(
            "a-descriptions-item",
            { attrs: { label: "身份证证件", span: 3 } },
            [
              _vm._l(
                [
                  _vm.baseInfo.identityCardFrontImgurl,
                  _vm.baseInfo.identityCardReverseImgurl
                ],
                function(item, index) {
                  return _c("img", {
                    key: index,
                    staticClass: "w-100",
                    attrs: { src: item, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImg(index, [
                          _vm.baseInfo.identityCardFrontImgurl,
                          _vm.baseInfo.identityCardReverseImgurl
                        ])
                      }
                    }
                  })
                }
              ),
              _vm.baseInfo.identityCardFrontImgurl
                ? _c(
                    "a-button",
                    {
                      staticClass: "ml-20",
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.handleUpdateUserCardInfo(_vm.baseInfo)
                        }
                      }
                    },
                    [_vm._v("更新身份证信息")]
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: "退货-收款人姓名" } },
            [
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.baseInfo.refundUserName))
              ]),
              _c(
                "a-button",
                {
                  staticClass: "ml-20",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleShowEditAddress }
                },
                [_c("a-icon", { attrs: { type: "edit" } }), _vm._v("编辑")],
                1
              )
            ],
            1
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: "退货-收款人手机号", span: 2 } },
            [
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.baseInfo.refundUserPhone))
              ]),
              _c(
                "a-button",
                {
                  staticClass: "ml-20",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleShowEditAddress }
                },
                [_c("a-icon", { attrs: { type: "edit" } }), _vm._v("编辑")],
                1
              )
            ],
            1
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: "退货-收款人地址", span: 3 } },
            [
              _vm.baseInfo.refundProvince
                ? _c("span", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.baseInfo.refundProvince))
                  ])
                : _vm._e(),
              _vm.baseInfo.refundCity
                ? _c("span", { staticClass: "font-bold ml-10" }, [
                    _vm._v(_vm._s(_vm.baseInfo.refundCity))
                  ])
                : _vm._e(),
              _vm.baseInfo.refundCounty
                ? _c("span", { staticClass: "font-bold ml-10" }, [
                    _vm._v(_vm._s(_vm.baseInfo.refundCounty))
                  ])
                : _vm._e(),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.baseInfo.refundUserAddress))
              ]),
              _c(
                "a-button",
                {
                  staticClass: "ml-20",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleShowEditAddress }
                },
                [_c("a-icon", { attrs: { type: "edit" } }), _vm._v("编辑")],
                1
              )
            ],
            1
          ),
          _c("a-descriptions-item", { attrs: { label: "注册时间", span: 1 } }, [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.baseInfo.createTime))
            ])
          ]),
          _c("a-descriptions-item", { attrs: { label: "过期时间", span: 2 } }, [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.baseInfo.shopExpireTime))
            ])
          ]),
          _c("a-descriptions-item", { attrs: { label: "支付时间", span: 1 } }, [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.baseInfo.paytime))
            ])
          ]),
          _c("a-descriptions-item", { attrs: { label: "支付金额", span: 2 } }, [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.baseInfo.payMoney))
            ])
          ]),
          _c("a-descriptions-item", { attrs: { label: "店铺标记", span: 3 } }, [
            _c("div", { staticClass: "desc-box" }, [
              !_vm.showSelectRefuseAuto
                ? _c(
                    "div",
                    { staticClass: "flex-start" },
                    [
                      !_vm.showSelect &&
                      (_vm.baseInfo.reviewTipMark || _vm.shopTagDescText)
                        ? _c(
                            "a-tag",
                            { attrs: { color: "red" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.confirmStatus
                                    ? _vm.shopTagDescText
                                    : _vm.baseInfo.reviewTipMark
                                ) + " "
                              ),
                              _c("a-icon", {
                                staticClass: "ml-10",
                                attrs: { type: "close-circle" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDeleteTag()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showSelect
                        ? _c(
                            "a-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: {
                                allowClear: "",
                                placeholder: "请选择标记"
                              },
                              on: { change: _vm.handleChangeSelectRefuseType },
                              model: {
                                value: _vm.shopTagDescTextById,
                                callback: function($$v) {
                                  _vm.shopTagDescTextById = $$v
                                },
                                expression: "shopTagDescTextById"
                              }
                            },
                            _vm._l(_vm.shopTagDescList, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.id, attrs: { value: item.id } },
                                [
                                  _c("span", [_vm._v(_vm._s(item.value))]),
                                  item.icon
                                    ? _c("a-icon", {
                                        staticStyle: {
                                          color: "#2d8cf0",
                                          "margin-left": "5px"
                                        },
                                        attrs: { type: item.icon }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.showSelect
                        ? _c("a-icon", {
                            staticStyle: {
                              "margin-left": "20px",
                              "font-size": "24px",
                              color: "dodgerblue",
                              cursor: "pointer"
                            },
                            attrs: { type: "check-circle" },
                            on: { click: _vm.handleConfirmShopTag }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "flex-start" },
                    [
                      _c("a-input", {
                        staticStyle: { width: "400px" },
                        attrs: { allowClear: "", placeholder: "输入店铺标记" },
                        model: {
                          value: _vm.shopTagDescText,
                          callback: function($$v) {
                            _vm.shopTagDescText = $$v
                          },
                          expression: "shopTagDescText"
                        }
                      }),
                      _vm.showSelect
                        ? _c("a-icon", {
                            staticStyle: {
                              "margin-left": "20px",
                              "font-size": "24px",
                              color: "dodgerblue",
                              cursor: "pointer"
                            },
                            attrs: { type: "check-circle" },
                            on: { click: _vm.handleConfirmShopTag }
                          })
                        : _vm._e(),
                      _c(
                        "a-tooltip",
                        { attrs: { placement: "top" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v("关闭自定义输入店铺标记")])
                          ]),
                          _c("a-icon", {
                            staticStyle: {
                              "margin-left": "20px",
                              "font-size": "24px",
                              color: "red",
                              cursor: "pointer"
                            },
                            attrs: { type: "close-circle" },
                            on: { click: _vm.handleCloseAuto }
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
              _c(
                "div",
                [
                  _c("a-button", {
                    staticClass: "ml-5",
                    attrs: {
                      icon: _vm.showSelect ? "close" : "edit",
                      size: "small"
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleChangeBondPrice(
                          _vm.baseInfo.reviewTipMark
                        )
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("a-descriptions-item", { attrs: { label: "营业执照" } }, [
            _c("img", {
              staticClass: "w-100",
              attrs: { src: _vm.baseInfo.businessLicense },
              on: {
                click: function($event) {
                  return _vm.handleShowBigImg(0, [_vm.baseInfo.businessLicense])
                }
              }
            })
          ]),
          _c(
            "a-descriptions-item",
            { attrs: { label: "文物经营许可", span: 2 } },
            [
              _c("img", {
                staticClass: "w-100",
                attrs: { src: _vm.baseInfo.relicsLicense },
                on: {
                  click: function($event) {
                    return _vm.handleShowBigImg(0, [_vm.baseInfo.relicsLicense])
                  }
                }
              })
            ]
          ),
          _vm.checkType
            ? _c(
                "a-descriptions-item",
                { attrs: { label: "审核店铺", span: 3 } },
                [
                  _c(
                    "div",
                    { staticClass: "flex-start-center" },
                    [
                      _c("a-input", {
                        attrs: {
                          type: "textarea",
                          "auto-size": { minRows: 6, maxRows: 10 },
                          placeholder: "拒绝原因"
                        },
                        model: {
                          value: _vm.baseInfo.reviewMsg,
                          callback: function($$v) {
                            _vm.$set(_vm.baseInfo, "reviewMsg", $$v)
                          },
                          expression: "baseInfo.reviewMsg"
                        }
                      }),
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-20",
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.handleCheckOverShop(-1)
                            }
                          }
                        },
                        [_vm._v("拒绝")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-20",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleCheckOverShop(2)
                            }
                          }
                        },
                        [_vm._v("通过")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("ShopAddressEditorPopup", {
        ref: "shopAddressEditorPopupEl",
        on: {
          setBaseInfo: function($event) {
            return _vm.handleSetBaseInfo($event)
          }
        }
      }),
      _c("ShopUserCardInfoUpdatePopup", {
        ref: "shopUserCardInfoUpdatePopupEl",
        on: {
          updateUserCardInfo: function($event) {
            return _vm.handleUpdateUserCardInfoSuccess($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }