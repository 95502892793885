<template>
  <div>
    <!-- :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }" -->
    <a-table
      @change="changePage"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :scroll="{ x: 800 }"
      :rowKey="(record) => record.spliderId"
    >
      <div
          slot="coinNameSlot"
          slot-scope="row"
      >
        <div>{{ row.coinName }}</div>
        <div>{{ row.createTime }}</div>
      </div>
      <div
          slot="userNikiName"
          slot-scope="row"
      >
        <div class="user-nike-name">
          <div @click="handleShowNikeName(row.userName)">{{ row.userName }}</div>
          <div @click="handleShowNikeName(row.userId)">{{ row.userId }}</div>
        </div>
      </div>

      <div
          slot="paySlot"
          slot-scope="row"
          :style="{color: row.payStatus === 1 ? 'green' : row.payStatus === 0 ? 'red' : 'orange'}"
      >
        {{ row.payMoney }}({{ row.payStatus == 1 ? '已支付' : row.payStatus === 0 ? '未支付' : '已退款' }})
      </div>

      <div
        slot="confirmExtend"
        slot-scope="row"
      >
        <div>{{ row.confirmExtend }}</div>
        <div style="margin-top: 10px">
          <a-button
              v-if="row.confirmExtend"
              class="change-btn"
              size="small"
              @click="handleChangeExtend(row)"
          >修改老师描述</a-button>
        </div>
      </div>

      <div
          slot="answerSlot"
          slot-scope="row"
      >
        <div :style="{color: row.confirmUserName ? 'green' : ''}">{{ row.confirmUserName }}</div>
        <div style="color: #001529">{{ row.confirmTime }}</div>
      </div>

      <div
          slot="versionSlot"
          slot-scope="row"
      >
        <span v-if="row.customAnswerVersion">{{ row.customAnswerVersion }}</span>
        <span v-else-if="row.coinVersionId"> {{ row.coinItemName }}.{{ row.coinVersionName }}</span>
      </div>

      <div
          slot="statusSlot"
          slot-scope="row"
      >
        <span style="color: red" v-if="row.confirmStatus == 0">待回答</span>
        <span style="color: green" v-if="row.confirmStatus == 1">已回答</span>
        <span style="color: blue" v-if="row.confirmStatus == 10">已修正</span>
      </div>

      <div
          slot="handleSlot"
          slot-scope="row"
      >
        <span style="color: green" v-if="row.operateStatus == -1">搁置</span>
        <span style="color: red" v-if="row.operateStatus == 0">待处理</span>
        <span style="color: orange" v-if="row.operateStatus == 10">已进样本库</span>
        <span style="color: orange" v-if="row.operateStatus == 20">已进底库</span>
      </div>
      

      <div
        slot="image_1"
        slot-scope="row"
        class="image"
        @click="previewImg(row,1)"
      >
        <img
          style="max-width: 80px; max-height: 80px"
          :src="row.frontImage"
        />
      </div>
      <div
        slot="image_2"
        slot-scope="row"
        class="image"
        @click="previewImg(row,2)"
      >
        <img
          style="max-width: 80px; max-height: 80px"
          :src="row.backImage"
        />
      </div>

      <div
        slot="setup"
        slot-scope="row"
        style="display: flex;"
      >
        <div style="margin-top: 5px; display: flex;justify-content: center;flex-direction: column;align-items: center;">
          <a-button
              size="small"
              type="danger"
              @click="handleChange(row)"
          >修改答案</a-button>

          <a-popconfirm
              title="是否确认?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="confirmDuibanData(row.id,10)"
              :disabled="row.operateStatus == 20 || row.operateStatus == 10"
          >
            <a-button
                size="small"
                type="primary"
                style="margin-top: 5px"
                :disabled="row.operateStatus == 20 || row.operateStatus == 10"
            >进样本库</a-button>
          </a-popconfirm>

          <a-popconfirm
              title="是否确认?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="confirmDuibanData(row.id,20)"
              :disabled="row.operateStatus == 20 || row.operateStatus == 10"
          >
            <a-button
                size="small"
                type="primary"
                style="margin-top: 5px"
                :disabled="row.operateStatus == 20 || row.operateStatus == 10"
            >确认进底库</a-button>
          </a-popconfirm>

          <a-button
              size="small"
              type="primary"
              style="margin-top: 5px"
              @click="handleGoToDuibanPage(row)"
          >机器对版结果</a-button>
          <a-button
              size="small"
              type="danger"
              :disabled="row.payStatus !== 1"
              style="margin-top: 5px"
              @click="handleReturnPrice(row)"
          >退款</a-button>
        </div>
      </div>
    </a-table>
    <!--  -->
    <ModalPush
      ref="pushModal"
      @success="pushSuccess"
    ></ModalPush>
    <ConfirmLog ref="log"></ConfirmLog>
    <ChangeData
        @success="editSuccess"
        ref="change" />
    <ChangeExtend ref="changeExtendPopupEl" @change="pushSuccess"/>
    <!-- 原图对版 -->
    <ViewPicture ref="showPicture"></ViewPicture>
  </div>
</template>

<script>
import ModalPush from "@/views/cmsPage/versionManage/pretreatmentMark/ModalPush.vue";
import ConfirmLog from "@/views/cmsPage/versionManage/duibanorder/ConfirmLog.vue";
import ChangeData from "@/views/cmsPage/versionManage/duibanorder/_components/ChangeData";
import ChangeExtend from "@/views/cmsPage/versionManage/duibanorder/_components/ChangeExtend";
import ViewPicture from "@/views/cmsPage/versionManage/duibanrecord/ViewPicture.vue"
export default {
  props: ["list", "loading", "pagination"],
  components: {
    ViewPicture,
    ModalPush,
    ConfirmLog,
    ChangeData,
    ChangeExtend
  },
  data() {
    return {
      selectedRowKeys: [],
      columns: [
        { title: "id", align: "center", dataIndex: "id", width: 80 },
        { title: "对版用户", align: "center", scopedSlots: { customRender: "userNikiName" }, width: 80 },
        {
          title: "大币种",
          align: "center",
          scopedSlots: { customRender: "coinNameSlot" },
          width: 180,
        },
        {
          title: "图片",
          width: 180,
          align: "center",
          scopedSlots: { customRender: "image_1" },
        },
        // {
        //   title: "反图片",
        //   width: 180,
        //   align: "center",
        //   scopedSlots: { customRender: "image_2" },
        // },
        {
          title: "补充说明",
          align: "center",
          dataIndex: "extendDesc",
          width: 180,
        },
        {
          title: "支付情况",
          align: "center",
          scopedSlots: { customRender: "paySlot" },
          width: 120,
        },
        {
          title: "确版结果",
          align: "center",
          scopedSlots: { customRender: "versionSlot" },
          width: 180,
        },
        {
          title: "确版说明",
          align: "center",
          scopedSlots: { customRender: "confirmExtend" },
          width: 180,
        },
        {
          title: "回答老师",
          align: "center",
          scopedSlots: { customRender: "answerSlot" },
          width: 180,
        },
        // {
        //   title: "确版时间",
        //   align: "center",
        //   dataIndex: "confirmTime",
        //   width: 180,
        // },
        {
          title: "状态",
          align: "center",
          scopedSlots: { customRender: "statusSlot" },
          width: 80,
        },

        {
          title: "操作状态",
          align: "center",
          scopedSlots: { customRender: "handleSlot" },
          width: 120,
        },
        {
          title: "操作",
          align: "center",
          // fixed: 'right',
          scopedSlots: { customRender: "setup" },
          width: 200,
        },
      ],
      duiBanList: [],
      duiBanParams: {
        pageNum: 1,
        pageSize: 20,
        recordId: undefined,
        testChoose: 0
      },
    };
  },
  watch: {
    loading() {
      this.selectedRowKeys = [];
      this.$emit("selectChange", []);
    },
  },
  methods: {
    /** 退款 */
    handleReturnPrice(row) {
      this.$refs.changeExtendPopupEl.show({confirmExtend: ''}, row.id, 'price')
    },
    /** 修改老师描述 */
    handleChangeExtend(row) {
      this.$refs.changeExtendPopupEl.show({confirmExtend: row.confirmExtend}, row.id, 'text')
    },
    /** 搜索当前昵称用户的付款列表 */
    handleShowNikeName(nameOrId) {
      const origin = location.origin
      const url = `${origin}/#/cms/versionManage/duibanorder?userName=${nameOrId}`;
      window.open(url)
    },
    editSuccess() {
      this.$emit("editSuccess");
    },
    /** 机器对版-原图对版 */
    async handleGoToDuibanPage(row) {
      this.duiBanParams.recordId = row.duibanId
      const res = await this.axios("/dq_admin/duibanUserRecord/getDuibanUserRecordLog", {
        params: this.duiBanParams
      });
      this.isLoading = false
      if (res.status !== '200') return;
      this.duiBanList = res.data.records;
      await this.$refs["showPicture"].show(this.duiBanList[0], 1, '', this.duiBanParams.testChoose);
    },
    /** 修改 */
    handleChange(row) {
      this.$refs["change"].show(row);
    },
    logClick(item){
      this.$refs["log"].show(item)
    },
    // 推送成功
    pushSuccess() {
      this.$emit("success");
    },
    // 推送到样本库点击
    pushClick(row) {
      this.$refs["pushModal"].show(row);
    },
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
      this.$emit("selectChange", arr);
    },
    // 推送同步
    async confirmDuibanData(id,type) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/duibanConfirmOrder/confirmToDiKuOrSample",
        {
          params: { id:id,type:type },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
    // 删除
    async deleteItem(id) {
      this.$loading.show();
      const res = await this.axios("/dq_admin/duibanSampleData/delById", {
        params: {
          recordId: id + "",
        },
      });
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("deleteItem", id);
    },
    // 切换分页
    changePage(page) {
      this.$emit("changePage", page);
    },
    // 预览封面
    previewImg(row,type) {
      var images = [{ img_url: row.frontImage }, { img_url: row.backImage }];
      if(type == 2){
        images = [{ img_url: row.backImage }, { img_url: row.frontImage }];
      }
      this.$previewImg({
        list: images,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-nike-name {
  cursor: pointer;
  color: #2d8cf0;
}
.title-content {
  max-width: 600px;
}
</style>