var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { margin: "5px", width: "130px" },
              attrs: { allowClear: "", placeholder: "id关键字", size: "small" },
              on: { pressEnter: _vm.search },
              model: {
                value: _vm.params.keyword,
                callback: function($$v) {
                  _vm.$set(_vm.params, "keyword", $$v)
                },
                expression: "params.keyword"
              }
            }),
            _c("a-input", {
              staticStyle: { margin: "5px", width: "130px" },
              attrs: {
                allowClear: "",
                placeholder: "昵称或userId",
                size: "small"
              },
              on: { pressEnter: _vm.search },
              model: {
                value: _vm.params.userName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "userName", $$v)
                },
                expression: "params.userName"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "180px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  "filter-option": _vm.untils.filterOption,
                  allowClear: "",
                  showSearch: "",
                  size: "small",
                  placeholder: "请选择大币种"
                },
                on: { change: _vm.changeCoin, search: _vm.coinSearch },
                model: {
                  value: _vm.params.coinId,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "coinId", $$v)
                  },
                  expression: "params.coinId"
                }
              },
              _vm._l(_vm.bigCoinList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.coinKindName))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "180px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  "filter-option": _vm.untils.filterOption,
                  allowClear: "",
                  showSearch: "",
                  size: "small",
                  placeholder: "请选择小币种"
                },
                on: { change: _vm.changeCoinItem },
                model: {
                  value: _vm.params.itemId,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "itemId", $$v)
                  },
                  expression: "params.itemId"
                }
              },
              _vm._l(_vm.smallCoinList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.coinKindItemName))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "280px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  allowClear: "",
                  showSearch: "",
                  "filter-option": _vm.untils.filterOption,
                  size: "small",
                  dropdownMenuStyle: { "max-height": "500px" },
                  placeholder: "版别"
                },
                on: { change: _vm.changeVersion },
                model: {
                  value: _vm.params.versionId,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "versionId", $$v)
                  },
                  expression: "params.versionId"
                }
              },
              _vm._l(_vm.versionList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s("" + item.coinKindVersionName))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "180px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  allowClear: "",
                  size: "small",
                  placeholder: "状态刷选"
                },
                model: {
                  value: _vm.params.confirmStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "confirmStatus", $$v)
                  },
                  expression: "params.confirmStatus"
                }
              },
              _vm._l(_vm.statusList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.value, attrs: { value: item.value } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
            _c(
              "a-button",
              {
                staticClass: "btn-item",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            ),
            _c(
              "a-popconfirm",
              {
                attrs: {
                  title: "Are you sure confirm this item?",
                  "ok-text": "Yes",
                  "cancel-text": "No",
                  disabled: _vm.isDisabled
                },
                on: {
                  confirm: function($event) {
                    return _vm.batchSampleSend()
                  }
                }
              },
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { disabled: _vm.isDisabled, size: "small" }
                  },
                  [_vm._v("批量确认进样本库")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "page-body" },
        [
          _c("PageTable", {
            ref: "table",
            attrs: {
              list: _vm.list,
              loading: _vm.loading,
              pagination: _vm.pagination
            },
            on: {
              selectChange: _vm.onSelectChange,
              changePage: _vm.changePage,
              success: _vm.getList,
              deleteItem: _vm.getList,
              editSuccess: _vm.editSuccess
            }
          })
        ],
        1
      ),
      _c("ModalUpload", { ref: "upload", on: { success: _vm.getList } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }