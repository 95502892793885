export const statusList = [
  { name: "待回答", value: 0 },
  { name: "已回答", value: 1 },
  { name: "已修正", value: 10 },
]

export const statusValue = (value)=>{
  const result = statusList.find(el=>{
    return value == el.value
  })
  return result.name
}