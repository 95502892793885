<template>
  <div class="page">
    <div class="page-header">
      <div>
        <a-input
            allowClear
            placeholder="id关键字"
            v-model="params.keyword"
            @pressEnter="search"
            size="small"
            style="margin: 5px; width: 130px"
        />
        <a-input
          allowClear
          placeholder="昵称或userId"
          v-model="params.userName"
          @pressEnter="search"
          size="small"
          style="margin: 5px; width: 130px"
        />
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoin"
            @search="coinSearch"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="params.coinId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of bigCoinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="params.itemId"
            placeholder="请选择小币种"
        >
          <a-select-option
              v-for="item of smallCoinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            @change="changeVersion"
            showSearch
            :filter-option="untils.filterOption"
            size="small"
            style="margin: 5px; width: 280px"
            v-model="params.versionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.id"
              :value="item.id"
          >{{ `${item.coinKindVersionName}` }}</a-select-option>
        </a-select>
        
        <!-- 状态刷选 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.confirmStatus"
          placeholder="状态刷选"
        >
          <a-select-option
            v-for="item of statusList"
            :key="item.value"
            :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>

        <a-button
          @click="search"
          size="small"
          class="btn-item"
          type="primary"
        >搜索</a-button>

        <a-popconfirm
          title="Are you sure confirm this item?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="batchSampleSend()"
          :disabled="isDisabled"
        >
          <a-button
            :disabled="isDisabled"
            size="small"
            style="margin-left: 10px"
          >批量确认进样本库</a-button>
        </a-popconfirm>
      </div>
    </div>
    <div class="page-body">
      <PageTable
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @selectChange="onSelectChange"
        @changePage="changePage"
        @success="getList"
        @deleteItem="getList"
        @editSuccess="editSuccess"
        ref="table"
      ></PageTable>
    </div>
    <ModalUpload ref="upload" @success="getList"></ModalUpload>
  </div>
</template>

<script>
import { statusList } from "@/views/cmsPage/versionManage/duibanorder/data.js";
import PageTable from "@/views/cmsPage/versionManage/duibanorder/PageTable.vue";
import ModalUpload from "@/views/cmsPage/versionManage/duibanorder/ModalUpload.vue";
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      isTest: undefined,
      statusList: statusList,
      selctedArrays: [],
      list: [],
      loading: false,
      detail: "",
      params: {
        userName: undefined,
        keyword: undefined,
        pageNum: 1,
        pageSize: 20,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
      /** 搜索小币种+版别所需的值 */
      coinSid: ''
    };
  },
  computed: {
    isDisabled() {
      return this.selctedArrays.length <= 0;
    },
  },
  filters: {
    versionText(item) {
      return `${item.versionName}[${item.aliasName || ""}](${item.size})`;
    },
  },
  components: {
    PageTable,
    ModalUpload,
  },
  async mounted() {
    try {
      this.params.userName = this.$route.query.userName
    } catch (e) {}
    await this.getList();
  },
  methods: {
    editSuccess() {
      this.getList()
    },
    async handleChangeCheckBox(e) {
      if (e.length < 1) {
        this.isTest = undefined
        delete this.params.isTest
      }
      await this.search()
    },
    uploadClick() {
      this.$refs["upload"].show();
    },
    // 获取table选中数据
    onSelectChange(selctedArrays) {
      this.selctedArrays = JSON.parse(JSON.stringify(selctedArrays));
    },
    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.bigCoinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.coinSid },
      });
      const { coinItem } = res.data;
      this.smallCoinList = coinItem;
    },
    // 大币种改变
    changeCoin(val) {
      delete this.params.itemId
      delete this.params.versionId
      if (!val) return;
      const coin = this.bigCoinList.find((el) => {
        return el.id == this.params.coinId;
      });
      this.coinSid = coin.sid;
      this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem(val) {
      delete this.params.versionId;
      if (!val) return;
      const coinItem = this.smallCoinList.find((el) => {
        return el.id == this.params.itemId;
      });
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion(val) {
      if (!val) return;
      const version = this.versionList.find((el) => {
        return el.id == this.params.versionId;
      });
    },
    // 批量删除
    async batchDelete() {
      let spliderIds = this.selctedArrays.map((row) => row.spliderId);
      let ids = spliderIds.join(",");
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/deleteById",
        {
          params: { ids: ids },
        }
      );
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      // spliderIds.forEach(el=>{
      //   deleteItem(el.spliderId)
      // })
      this.getList();
    },
    // 批量推送
    async batchSend() {
      let ids = this.selctedArrays.map((row) => row.spliderId);
      ids = ids.join(",");
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/quanhaiVersionDataSplider/confirmationData",
        {
          params: { spliderIds: ids },
        }
      );
      this.loading = false;
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.getList();
    },
    // 分页
    changePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },

    // 数据列表
    async getList() {
      if (this.isTest == 1) {
        this.params.isTest = 1
      }
      this.loading = true;
      const res = await this.axios("/dq_admin/duibanConfirmOrder/list", {
        params: this.params,
      });
      this.loading = false;
      if (res.status != 200) return;
      this.pagination.total = res.data.total;
      this.list = res.data.records;
    },
    // 点击搜索按钮
    search() {
      this.pagination.current = 1;
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
.page-header {
  display: flex;
}
.btn-item {
  margin-left: 10px;
}
</style>