var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c(
        "a-modal",
        {
          attrs: { width: 600, centered: "", title: _vm.changTypeTitle },
          on: { ok: _vm.handleConfirmChangeExtend, cancel: _vm.cancel },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center"
              }
            },
            [
              _c(
                "div",
                { staticClass: "flex-start-center" },
                [
                  _c("span", [_vm._v("描述：")]),
                  _c("a-textarea", {
                    staticStyle: { width: "460px" },
                    attrs: {
                      allowClear: "",
                      "auto-size": { minRows: 4, maxRows: 6 },
                      placeholder: "请输入补充描述"
                    },
                    model: {
                      value: _vm.params.confirmExtend,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "confirmExtend", $$v)
                      },
                      expression: "params.confirmExtend"
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }